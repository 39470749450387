function initialize_maps() {
	var maps = $('.google-map').each(function () {
		var myLatlng = new google.maps.LatLng($(this).data('lat'), $(this).data('lng'));

		var map_options = {
			center: myLatlng,
			zoom: 12,
			mapTypeId: google.maps.MapTypeId.ROADMAP
		}

		var map = new google.maps.Map(this, map_options);

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map
		});
	});
}
google.maps.event.addDomListener(window, 'load', initialize_maps);