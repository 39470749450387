$(window).on('load', function() {
	$('body').addClass('loaded');

	if ($('.section-default .gform_wrapper').length) {
		$('body').addClass('hasForm');
	}
});

$(window).on('load scroll', function() {
	let scroll = $(window).scrollTop();

	if (scroll >= 1) {
		$('body').addClass('scrolled');
	} else {
		$('body').removeClass('scrolled');
	}
});