$.fn.equalizeHeight = function() {
	let maxHeight = 0, itemHeight;

	this.css('height', '');

	for (let i = 0; i < this.length; i++) {
		itemHeight = $(this[i]).height();
		if (maxHeight < itemHeight) {
			maxHeight = itemHeight;
		}
	}

	return this.height(maxHeight);
}

$(window).on('load resize', function() {
	$('.section-news .grid__col h2').equalizeHeight();

	equalizeHeightOfNewsGridWithPols();
});

$(window).on('load', function() {
	let interval;



	$('.section-news .totalpoll-form').on('submit', function() {
		const $this = $(this);
		const $wrapper = $this.closest('.grid__col');

		interval = setInterval(function() {
			console.log(true)
			if ($wrapper.find('.totalpoll-wrapper').is('.is-screen-results')) {
				equalizeHeightOfNewsGridWithPols();

				clearInterval(interval);
			}
		}, 50);
	});
});

function equalizeHeightOfNewsGridWithPols() {
	const $sectionNews = $('.section-news');

	$sectionNews.each((index, elem) => {
		const $elem = $(elem);
		const $newsLists = $elem.find('.grid__col ul');

		let btnHeight = 0;

		$newsLists.each((index, elem) => {
			const $btn = $(elem).next('a');

			if ($btn.length) {
				var marginTop = parseInt($btn.css('marginTop').replace('px', ''));
				var marginBottom = parseInt($btn.css('marginBottom').replace('px', ''));
				var height = $btn.outerHeight() + marginTop + marginBottom;

				if (height > btnHeight) {
					btnHeight = height;
				}
			}
		});


		$newsLists.equalizeHeight();

		let formHeight = parseInt($($newsLists[0]).css('height').replace('px', ''));

		if ($elem.find('.totalpoll-wrapper.is-screen-results').length) {
			formHeight += btnHeight - 27;
		}

		$elem.find('.totalpoll-questions').css({
			'height': `${formHeight}px`,
		})
	});
}
