$('.btn--menu').on('click', function (e) {
	$(this).toggleClass('is-active');
	$('body').toggleClass('is-active');
	e.preventDefault();
});

$(window).on('load resize', function() {
	if ( $(window).width() < 1199 ){
		$('.nav li:has(ul)').addClass('hasSubMenu');

		$('.nav li:has(ul) > a').on('click', function(e){
			console.log('in');
			if(!$(this).parent().hasClass('clicked_link')) {
				$(this).parent().siblings().removeClass('clicked_link');
				$(this).parent().addClass('clicked_link');
				$(this).next().show(400);
				$(this).parent().siblings().find('ul').hide(400)
				e.preventDefault();
			} else {
				return true;
			}
		});
	} else {
		$('.nav li:has(ul)').removeClass('hasSubMenu');
	}
});
