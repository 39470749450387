import 'magnific-popup';

$(window).on('load', function() {
	$('.video-js').magnificPopup({
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});
});
